
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const isOpen: { [key: string]: boolean } = reactive({
      first: false,
      second: false,
      third: false,
    });

    const toggle = (key: string) => (isOpen[key] = !isOpen[key]);

    return { t, toggle, isOpen };
  },
});
